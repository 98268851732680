<template>
  <div>
    <div class="row mb-2">
      <div class="col-8">
        <div class="row">
          <div class="col-6">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('lbl_search')"
              debounce="500"
              @update="handleGetListData"
            />
          </div>
        </div>
      </div>
    </div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <b-table
        ref="refProductsTable"
        class="position-relative"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('lbl_no_data')"
        tbody-class="cs-table cursor-pointer"
        @row-clicked="handleViewDetail"
      >
        <template #cell(receipt_code)="data">
          <span
            v-if="data.item.receipt_code"
            class="text-capitalize"
          >{{ `#${(data.item.receipt_code)}` }}</span>
        </template>
        <template #cell(created_at)="data">
          <span class="text-capitalize">{{ formatDateTime(data.item.created_at) }}</span>
        </template>
        <template #cell(total_price)="data">
          <span class="text-capitalize">{{ appUtils.numberFormat(data.item.amount_received) }}</span>
        </template>
        <template #cell(status)="data">
          <div :set="statusInfo=getStatusInfo(data.item)">
            <span class="font-bold" :class="statusInfo.class">{{ statusInfo.label }}</span>
          </div>
        </template>
      </b-table>
    </b-overlay>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >{{ $t('fm_pagination',{from:dataMeta.from,to:dataMeta.to,total:dataMeta.of}) }}</span>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProducts"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";
import vSelect from "vue-select";
import { PAYMENT_TYPE } from "@/utils/constant";

const tableColumns = [
  { key: "pharma_receipt_code", label: "Mã phiếu", sortable: false },
  {
    key: "customer_code",
    label: "Mã Khách hàng",
    sortable: false
  },
  {
    key: "customer_name",
    label: "Tên Khách hàng",
    sortable: false
  },
  {
    key: "status",
    label: "Trạng Thái",
    sortable: false
  },
  {
    key: "total_price",
    label: "Tổng tiền",
    sortable: false
  },
  {
    key: "created_at",
    label: "Ngày tạo",
    sortable: false
  }
];

export default {
  name: "ReceiptManager",
  components: { vSelect },
  data() {
    return {
      isLoading: false,
      dataList: [],
      currentPage: 1,
      perPage: 15,
      totalProducts: 0,
      searchQuery: "",
      tableColumns,
      appUtils
    };
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.dataList?.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalProducts
      };
    }
  },
  watch: {
    currentPage() {
      this.handleGetListData();
    }
  },
  async created() {
    this.handleGetListData();
  },

  methods: {
    handleViewDetail(data) {
      this.$router.push({ name: "ReceiptDetail", params: { id: data.id } });
    },
    async handleGetListData() {},
    formatDateTime(date) {
      return window.moment(date).format("HH:mm DD/MM/YYYY");
    },
    getSupplierLabel(data) {
      return data?.supplier_info?.supplier_name;
    },
    handleChangeInventory(inventory) {
      this.inventoryInfo = inventory;
      this.handleGetListData();
    },
    getStatusInfo(data) {
      if (!data.payment_status) return;

      return PAYMENT_TYPE.find(item => item.value === data.payment_status);
    }
  }
};
</script>
  
  <style lang="scss" scoped>
</style>